import './App.css';
import React, { useState } from 'react'
import NavBar from './components/Navbar';
import News from './components/News';
import {BrowserRouter as Router,Switch,Route,} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Footer from './components/Footer';
import ScrolltoTop from './components/scrolltoTop';


const App =()=> {

  const apiKey =  process.env.REACT_APP_NEWS_API
  const[progress, setProgress]= useState(0)
  // const page=1

    return (
      <div>
        <Router>
          <ScrolltoTop/>
        <NavBar/> 

        <LoadingBar
        color='#f11946'
        height={3}
        progress={progress}
        /> 
        <Switch>
          <Route exact path="/">              <News     setProgress = {setProgress}  apiKey={apiKey} key="general"         category="general"/>         </Route> 
          <Route exact path="/business">      <News     setProgress = {setProgress}  apiKey={apiKey} key="business"        category="business"/>        </Route> 
          <Route exact path="/entertainment"> <News     setProgress = {setProgress}  apiKey={apiKey} key="entertainment"   category="entertainment"/>   </Route> 
          <Route exact path="/general">       <News     setProgress = {setProgress}  apiKey={apiKey} key="general"         category="general"/>         </Route>
          <Route exact path="/health">        <News     setProgress = {setProgress}  apiKey={apiKey} key="health"          category="health"/>          </Route> 
          <Route exact path="/science">       <News     setProgress = {setProgress}  apiKey={apiKey} key="science"         category="science"/>         </Route> 
          <Route exact path="/sports">        <News     setProgress = {setProgress}  apiKey={apiKey} key="sports"          category="sports"/>          </Route> 
          <Route exact path="/technology">    <News     setProgress = {setProgress}  apiKey={apiKey} key="technology"      category="technology"/>      </Route>  
        </Switch>
        </Router>
        <Footer/>
      </div>
    )
  
}
export default App;