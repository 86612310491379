import React from 'react'


const Footer = () => {

  return (
    
    <div className='footer' style={{marginTop:'12rem', height:'7rem', width:'100vw', backgroundColor:'black', color:'#A9A9A9',position:'relative',bottom:'0px',display:'flex', alignItems:'center',justifyContent:'center'}}>
        <div className='footerText' style={{fontSize:'0.9rem'}}>
            copyright © 2023 NewsToday by Parivartan Kolnurkar
        </div>
    </div>
 
  )
}



export default Footer
