import React  from "react";
// import PropTypes from "prop-types";

const NewsItem=(props)=>{

  let {title, description, imgUrl, newsUrl, author, date, source} = props
    return (
      <div className="my-5 mx-2 ">
        <div className="card " >
          <div style={{display:'flex', justifyContent: 'flex-end', position:'absolute', right: '0'}}>
        <span className="badge rounded-pill bg-danger" >{source}</span>
        </div>
            
          <img src={imgUrl? imgUrl :'https://i.pinimg.com/originals/fe/be/de/febede61e6bc4a70c15334d0ad9bc989.jpg'} style={{height:'14rem'}} className="card-img-top" alt="..." />

          <div className="card-body">
            <h5 className="card-title">{title}... </h5>
            <p className="card-text">{description}...</p>
            <p className="card-text"><small className="text-danger">By {!author?"Unknow":author} on {new Date(date).toGMTString()}</small></p>
            <a href={newsUrl} target='_blank' rel="noreferrer" className="btn btn-sm btn-dark">Read More</a>
          </div>
          
        </div>
      </div>
    );
  
}

export default NewsItem;


