import React, { Component } from "react";
import NewsItem from "./NewsItem";
import Spinner from "./Spinner";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

export class News extends Component {
  static defaultProps = {
    category: "general",
    pageSize: 10
  };

  static propTypes = {
    category: PropTypes.string,
    pageSize: PropTypes.number
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: false,
      page: 1,
      totalResults: 0,
      pageSize:10
    };
    document.title = `${this.capitalizeFirstLetter(this.props.category)} - NewsToday`; 
  }


  async componentDidMount(props) {

    this.props.setProgress(15)
    let url = `https://${this.props.apiKey}${this.props.category}.json`
    this.setState({ loading: true });
    this.props.setProgress(35)
    
    let data = await fetch(url);
    let parsedData = await data.json(); 
    
    this.props.setProgress(65)
    
    let setArticles= parsedData.articles.slice(0,24)
   
    
    this.setState({
  
      articles: setArticles,
      totalResults: parsedData.articles.length,
      loading: false,
      page:1,
    }); 
    this.props.setProgress(100)
  

    console.log("Hey, I'm the owner of NewsToday. I have built this website using REACT JS. Hope you Like it!!")
    

  }
 
  


  fetchMoreData = async () => {
    this.setState({ page: this.state.page + 1 });
    let Url = `https://${this.props.apiKey}${this.props.category}.json`
   
    let data = await fetch(Url);
    let parsedData = await data.json();

    this.setState({
      articles: this.state.articles.concat(parsedData.articles.slice((this.state.page-1)*24,this.state.page*24)),
      
      
    });
  };


  render() {
    return (
      <>
        <h1 className="text-center " style={{position: 'relative', margin:'11rem auto 0'}}>
          NewsToday - Top {this.capitalizeFirstLetter(this.props.category)}{" "}
          Headlines{" "}

        </h1>
        {this.state.loading && <Spinner/>}

      <div style={{ paddingTop:'2rem'}}>
        
        <InfiniteScroll
          dataLength={this.state.articles.length}
          next={this.fetchMoreData}
          hasMore={this.state.articles.length !== this.state.totalResults}
         
          loader={<Spinner />}
        >
          <div className="container my-3 ">
            <div className="row ">
              {this.state.articles.map((element) => {
                return (
                  <div className="col-md-4" key={element.url + Math.random(0,300)}>
                    <NewsItem
                      title={element.title? element.title.slice(0, 62) : ""}
                      description={element.description? element.description.slice(0, 88): ""}
                      imgUrl={element.urlToImage}
                      newsUrl={element.url}
                      author={element.author}
                      date={element.publishedAt}
                      source={element.source.name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default News;





